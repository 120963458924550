import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../globalStyles';

const StyledTechIcons = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	max-width: 1511px;
	margin: 0 auto;
	.icon-wrapper {
		max-width: 16%;
		width: auto;
		height: auto;
		max-height: 68px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			max-height: 100%;
			max-width: 100%;
		}
	}
	${media.maxMd`
		justify-content: center;
		flex-wrap: wrap;
		.icon-wrapper{
			width: 20%;
			margin: 20px;
		}
	`}
	${media.laptopS`
		img{
			width: auto;
		}
	`}
	.show-sm {
		display: none;
		${media.minXs`
		display:none;
	`};
	}
`;

const TechIcons = ({ icons, hideSm, visibleSm }) => (
	<StyledTechIcons
		className={`${hideSm === true ? 'hidden-sm' : ''} ${
			visibleSm === true ? 'show-sm' : ''
		}`}
	>
		{icons.map((item, i) => (
			<span className="icon-wrapper" key={i}>
				<img src={item.icon} alt={item.alt} />
			</span>
		))}
	</StyledTechIcons>
);
TechIcons.propTypes = {
	icons: PropTypes.array,
	hideSm: PropTypes.bool,
	visibleSm: PropTypes.bool,
};

TechIcons.defaultProps = {
	hideSm: true,
	visibleSm: false,
};

export default TechIcons;
