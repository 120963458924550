import React from 'react';
import styled from 'styled-components';

import StepItem from './StepItem';
import { stepsData } from './StepsData';
import { media } from '../../../../globalStyles';

const StyledSteps = styled.div`
	margin: 164px auto 0;
	width: 100%;
	text-align: center;
	.text-content {
		margin-top: 30px;
	}
	${media.maxXs`
		margin-top: 40px;	
	`}
`;

const Steps = () => (
	<StyledSteps>
		{stepsData.map(item => (
			<StepItem
				noLineBefore={item.number === '01'}
				noLineAfter={item.number === '06'}
				key={item.number}
				revers={item.reverse}
				number={item.number}
				title={item.title}
				text={item.text}
			/>
		))}
	</StyledSteps>
);

export default Steps;
