export const stepsData = [
	{
		reverse: false,
		number: '01',
		title: 'Information Gathering',
		text:
			'First, we develop a solid understanding of your company, your goals, ' +
			'and your requirements. This helps us determine the right platform, code, ' +
			'and architecture to achieve your vision. Our team also considers the ' +
			'target audience you’re trying to reach, as well as the overall purpose of the project.',
	},
	{
		reverse: true,
		number: '02',
		title: 'Planning for Success',
		text:
			'Any successful project starts out with a detailed plan. You cannot ' +
			'simply start writing code and expect everything to fall into place. We ' +
			'focus on building a rock-solid foundation first to make sure our web and ' +
			'mobile apps operate 24/7 without failure. That is our guarantee!',
	},
	{
		reverse: false,
		number: '03',
		title: 'Time for a Prototype',
		text:
			'Once we have all the information we need and have planned your ' +
			'project, it’s time for the design. We develop a prototype (iterative ' +
			'app prototyping) that is a fully functioning example of your project. ' +
			'It allows us to test everything and to see if the program meets your goals.',
	},
	{
		reverse: true,
		number: '04',
		title: 'The Development Phase',
		text:
			'This is the point where the “rubber hits the road.” In other words,' +
			' we’ve got all we need to create the custom product that blows away ' +
			'your competitors. We take all the individual elements from the ' +
			'prototype and use them to create the actual, functional mobile app or website.',
	},
	{
		reverse: false,
		number: '05',
		title: 'Quality Assurance',
		text:
			'We are nothing without the quality results we produce. If your ' +
			'product does not meet your expectations, you’re not happy . . . and ' +
			'neither are we! We thoroughly test everything to ensure it is ' +
			'functioning the way it should, and it looks perfect. Most importantly, ' +
			'we let you do a test drive before deployment!',
	},
	{
		reverse: true,
		number: '06',
		title: 'Full Support & Maintenance',
		text:
			'It’s not over when it’s over! In other words, even after we’ve ' +
			'deployed your app, website, or software, we are always available' +
			' to provide support and maintenance. That’s because we stand by ' +
			'our products and you as our valued client. If you encounter a problem' +
			' or you need to change something, we are here to fulfill your demands.',
	},
];
