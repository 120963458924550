import styled, { css } from 'styled-components';
import commonInputStyles from './commonStyles/commonInputStyles';

const Input = styled.input`
	${commonInputStyles};
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}
	&::-webkit-input-placeholder {
		line-height: normal;
	}
	${({ disabled }) =>
		disabled &&
		css`
			&&& {
				cursor: default;
			}
		`}
`;

export default Input;
