import styled from 'styled-components';
import commonInputStyles from './commonStyles/commonInputStyles';

const Textarea = styled.textarea`
	${commonInputStyles};
	height: 303px;
	resize: none;
`;

export default Textarea;
