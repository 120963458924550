import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';

const StyledCodingLanguages = styled.div`
	max-width: 1510px;
	margin: auto;
	.text-content {
		margin-top: 30px;
	}
`;

const CodingLanguages = () => (
	<StyledCodingLanguages>
		<ContentSection
			fluid
			noTopBorder
			hiddenSm
			paddingBottom={0}
			title={'No Worries – We are Full-Stack Experts!  <span key={2}>:)</span>'}
			seoTitle={'h2'}
			seoSubTitle={'h4'}
			subtitle="Web/mobile app development is a holistic process. We consider content strategy, information architecture, user-pathways, and much more to achieve your goals."
		/>
	</StyledCodingLanguages>
);

export default CodingLanguages;
