export const mobileItemsContent = [
	{
		title: 'USER EXPERIENCE',
		text:
			'App development considers how the end-user operates. It is personalized programming.',
		image: '',
	},
	{
		title: 'RESPONSIVENESS',
		text:
			'It’s critical to build a web app that works well on mobile devices and other operating systems.',
		image: '',
	},
	{
		title: 'SCALABILITY',
		text:
			'Will the app work on a small database or large system? We make sure our apps are scalable.',
		image: '',
	},
	{
		title: 'REALIABILITY',
		text:
			'An app is not good if it doesn’t function well. <b>PixelTeh</b> tests it to ensure flawless operation.',
		image: '',
	},
	{
		title: 'SECURITY',
		text:
			'Using the most advanced security protocols, we make sure there are no data breaches.',
		image: '',
	},
];
