import React from 'react';
import PropTypes from 'prop-types';
import H3 from '../../_common/H3';
import H5 from '../../_common/H5';
import styled from 'styled-components';
import { fonts, media } from '../../../globalStyles';

const StyledTypographyItem = styled.div`
	width: 31vw;
	height: 31vw;
	border: 1px dotted #d2d2d2;
	border-radius: 600px;
	padding: 2px;
	text-align: center;
	.typography-sub-cover {
		background: #f4f4f4;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 600px;
	}
	.typography-item-subtitle {
		font-style: italic;
		font-size: 14px;
		margin-top: 30px;
		font-family: ${fonts.openSans};
		max-width: 355px;
		line-height: 26px;
	}
	${H3} {
		font-family: ${fonts.loveloBlack};
		font-size: 45px;
	}
	${H5} {
		margin-top: 20px;
		font-family: ${fonts.robotoMedium};
		font-size: 32px;
	}
	${media.over1600`
		width: 560px;
		height: 560px;
	`};
	${media.maxXl`
		width: 45vw;
		height: 45vw;
		max-width: 444px;
		max-height: 444px;
	`};
	${media.laptop`
		max-width: 370px;
		max-height: 370px;
		${H3}{
			font-size: 35px;
		};
		${H5}{
			margin-top: 10px;
			font-size: 28px;
		};
		.typography-item-subtitle{
			margin-top: 14px;
			line-height: 26px;
			max-width: 87%;
		}
	`};
	${media.laptopS`
			width: 45vw;
			height: 45vw;
	`};
	${media.tablet`
		${H3}{
			font-size: 26px;
		};
		${H5}{
			margin-top: 8px;
			font-size: 20px;
		};
		.typography-item-subtitle{
			margin-top: 16px;
			line-height: 20px;
			max-width: 80%;
		}
	`};
`;

const TypographyItem = ({ title, children }) => (
	<StyledTypographyItem className="hidden-sm">
		<div className="typography-sub-cover">
			<H3>{title}</H3>
			<H5>development</H5>
			<p className="typography-item-subtitle">{children}</p>
		</div>
	</StyledTypographyItem>
);

TypographyItem.propTypes = {
	icon: PropTypes.any,
	title: PropTypes.string,
	link: PropTypes.string,
	children: PropTypes.any,
	subtitle: PropTypes.string,
};
export default TypographyItem;
