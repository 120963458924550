import React from 'react';
import styled from 'styled-components';
import TypographyItem from './TypographyItem';
import arrows from './../../../assets/img/full-arc.png';
import question from './../../../assets/img/question-icon.png';
import { media } from '../../../globalStyles';

const StyledTypography = styled.div`
	max-width: 1540px;
	margin: 197px auto 0;
	.typography-block-cover {
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.img-cover {
		position: absolute;
		top: -60px;
		width: 36%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
		img {
			width: 100%;
			height: auto;
		}
	}
	.question-mark-block {
		align-self: center;
		height: 238px;
		width: auto;
		${media.laptopS`
			 height:auto;
			 width: 10%;
			 img{
			 	width:100%
			 	height:auto;
			 }
		`};
	}
`;
const TypographyQuestion = () => (
	<StyledTypography className="hidden-sm">
		<div className="typography-block-cover container-fluid">
			<div className="img-cover">
				<img src={arrows} alt="" />
			</div>
			<TypographyItem title={'FrontEnd'}>
				If a mobile app or website was a car, the front-end aspect of it would
				be the body’s design, the paint color, and even the sleek interior
				features. Front-end developers always have the end-user in mind and
				consider things like layout, visuals, and user interactions (UI) and
				user experience (UX).
			</TypographyItem>
			<div className="question-mark-block">
				<img src={question} alt="orange question mark" />
			</div>
			<TypographyItem title={'Backend'}>
				Let’s consider the car analogy again. A car may look beautiful, but if
				it doesn’t have an engine or all the electronic and computer parts, then
				it’s no good. Back-end development are the “gears” that make an app or
				website run. It involves a lot of coding and expertise to do it
				correctly.
			</TypographyItem>
		</div>
	</StyledTypography>
);

export default TypographyQuestion;
