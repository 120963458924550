import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
// Components
import ContentSection from '../ContentSection';
import PanoramaInfo from './PanoramaInfo';
import ContentSectionTopBorder from '../_common/ContentSectionTopBorder';
// Styles
import { media } from '../../globalStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//----------------------------------------------------------------------------------------------------------------------

const sliderSettings = {
	arrows: false,
	dots: true,
	infinite: true,
	autoplay: true,
};

const laptopTabletMedia = `
	&&& .slick-dots {
		bottom: 20px;
	}
`;

const Wrapper = styled.div`
	position: relative;
	.gatsby-image-wrapper{
		display: block!important;
	}
	.slick-dots {
		position: absolute;
		bottom: 100px;
		list-style: none;
		z-index: 1;
	}
	.slick-dots li {
    width: 13px;
    height: 13px;
    border: 2px solid white;
    border-radius: 50%;
	}
	.slick-dots li.slick-active {
    background: #ffffff;
	}
	.slick-dots li button::before {
		display: none;
	}
	${media.maxXxl`
		&&& .slick-dots {
			bottom: 40px;
		}
	`}
	${media.laptop`${laptopTabletMedia}`}
	${media.tablet`${laptopTabletMedia}`}
`;

const ProgrammingHub = () => {
	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { name: { regex: "/panorama/" } }) {
				edges {
					node {
						childImageSharp {
							# Specify the image processing specifications right in the query.
							# Makes it trivial to update as your page's design changes.
							fluid(maxWidth: 1920, quality: 90) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		}
	`);
	return (
		<ContentSection
			hiddenSm
			disablePadding
			paddingBottom={0}
			paddingBottomSm={0}
			fluid
			title={'Our New Programming Hub'}
			subtitle={'11,000 sq ft of floor space to better serve you'}
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<ContentSectionTopBorder />
			<Wrapper>
				<Slider {...sliderSettings}>
					{data.allFile.edges.map(({ node }, index) => (
						<Img
							alt={`panorama-${index}`}
							key={index}
							fluid={node.childImageSharp.fluid}
						/>
					))}
				</Slider>
				<PanoramaInfo />
			</Wrapper>
		</ContentSection>
	);
};

export default ProgrammingHub;
