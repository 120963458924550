import React from 'react';
import PropTypes from 'prop-types';
import H6 from '../../_common/H3';
import P from '../../_common/P';
import styled from 'styled-components';
import { colors, fonts, media } from '../../../globalStyles';

import lines from './../../../assets/img/lines.png';

const StyledSingleItem = styled.div`
	text-align: center;
	max-width: 233px;
	width: 30%;
	${P} {
		margin-top: 16px;
		color: #929398;
		font-family: ${fonts.robotoLightItalic};
		font-size: 15px;
		line-height: 30px;
	}

	${H6} {
		margin-top: 25px;
		font-family: ${fonts.loveloBlack};
		font-size: 17px;
		color: ${colors.blackTitle};
	}

	.mobile-img-cover {
		margin: auto;
		background-image: url(${lines});
		background-size: cover;
		box-shadow: 0 0 21px rgba(0, 0, 0, 0.13);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 152px;
		width: 152px;
		height: 152px;
	}
	${media.laptopS`
		max-width: none;
		width: 33%;
		:nth-child(n+4){
			margin-top:60px;
		}
	`}
	${media.tablet`
		width: 100%;
		margin-top: 60px;	
		&:first-child {
			margin-top: 0;
		}
	`}
`;

const TypographyItem = ({ title, image, children }) => (
	<StyledSingleItem>
		<div className="mobile-img-cover">
			<img src={image.icon} alt={image.alt} />{' '}
		</div>
		<H6>{title}</H6>
		<P dangerouslySetInnerHTML={{ __html: children }} />
	</StyledSingleItem>
);

TypographyItem.propTypes = {
	icon: PropTypes.any,
	image: PropTypes.any,
	title: PropTypes.string,
	link: PropTypes.string,
	children: PropTypes.any,
};
export default TypographyItem;
