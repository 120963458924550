import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ContentSection from '../ContentSection';
import Form from '../Form';
import { media } from '../../globalStyles';

const StyledNewProjectForm = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 750px;
	margin: auto;
	.content {
		flex: 1;
		justify-content: center;
	}
`;
const customStyles = css`
	${media.maxSm`
		background-color: rgba(247, 247, 247, 0.5);
	`}
`;
const ContactForm = ({ label }) => (
	<ContentSection
		customStyles={customStyles}
		fluid
		bgColor="grey"
		title="Got a project mind?"
		subtitle="Let’s build a great product together"
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledNewProjectForm>
			<Form label={label} />
		</StyledNewProjectForm>
	</ContentSection>
);
ContactForm.propTypes = {
	label: PropTypes.string.isRequired,
};
export default ContactForm;
