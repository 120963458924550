import enterprise from '../../../assets/img/enterprise-icon.png';
import enterpriseM from '../../../assets/img/enterprise-icon-mobileS.png';
import horeca from '../../../assets/img/horeca.png';
import horecaM from '../../../assets/img/horec-mobile.png';
import marketing from '../../../assets/img/marketing.png';
import marketingM from '../../../assets/img/marketing-mobile.png';
import health from '../../../assets/img/health-care.png';
import healthM from '../../../assets/img/health-mobile.png';
import publishing from '../../../assets/img/media-publishing.png';
import publishingM from '../../../assets/img/media-publishing-mobile.png';
import networks from '../../../assets/img/social-networks.png';
import networksM from '../../../assets/img/social-networks-mobile.png';

const data = [
	{
		title: 'Enterprize Software',
		text:
			'Do you need programming for your small business, corporate site, or ' +
			'large organization? Not all companies have coders on site, yet most need ' +
			'customized apps or software for their business applications. Instead of ' +
			'hiring more staff, reach out to us for customized full-stack development.',
		icon: enterprise,
		mobileIcon: enterpriseM,
		alt: 'two story building icon',
	},
	{
		title: 'Hotel, Restaurant, & Catering',
		text:
			'One of the most effective ways to reach your target audience is by ' +
			'developing a custom website or app for your service-oriented company. ' +
			'This is especially important if you are in the hospitality or food ' +
			'industry. A basic app or website just won’t do. Improve your profits ' +
			'and expand your business with our help.',
		icon: horeca,
		mobileIcon: horecaM,
		alt: 'building with stars',
	},
	{
		title: 'Marketing and Advertising',
		text:
			'Gone are the days when a newspaper ad or a billboard would entice ' +
			'customers. Even now, many people ignore commercials on TV. Why not ' +
			'reach your target audience in a better way? <b>PixelTeh</b> develops ' +
			'customized apps that include advanced features, such as ' +
			'Augmented and Virtual Reality.',
		icon: marketing,
		mobileIcon: marketingM,
		alt: 'megaphone and bar graph',
	},
	{
		title: 'Healthсare',
		text:
			'This is where programming is absolutely exploding! As the world’s ' +
			'population expands, there is an ever- growing need for programming' +
			' that helps healthcare specialists provide state-of-the-art services' +
			' for their patients. We create AI, ML, and VR platforms that take' +
			' medical care into the 21<sup>st</sup> century and beyond.',
		icon: health,
		mobileIcon: healthM,
		alt: 'medical icon',
	},
	{
		title: 'Media & Publishing',
		text:
			'Reach the world with your stories, programs, and published work. ' +
			'It’s not enough to expect your consumers to find you. Your industry ' +
			'is saturated with so many options and a multitude of competitors. Make ' +
			'it easy and appealing for your target market to discover you. Come' +
			' to our team for solutions.',
		icon: publishing,
		mobileIcon: publishingM,
		alt: 'finger touching a screen',
	},
	{
		title: 'Social Networks',
		text:
			'Let’s get social! Do you want a new social app? Just because ' +
			'Facebook started it all doesn’t mean you can’t create something ' +
			'new. After all, look at Instagram and Snap Chat. They have made ' +
			'a name for themselves. <b>PixelTeh</b> can take your ideas and create a ' +
			'social app that draws the attention you want.',
		icon: networks,
		mobileIcon: networksM,
		alt: 'circle with four balls',
	},
];


export default data;
