import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';

import P from '../../_common/P';
import Steps from './Steps';
import H2 from '../../_common/H2';

const StyledBestWay = styled.div`
	margin: auto;
	.text-content {
		margin-top: 30px;
	}
	${P} {
		line-height: 30px;
		padding-bottom: 0;
	}
	.steps-wrapper {
		max-width: 930px;
		margin: 0 auto;
	}
	${H2} {
		max-width: 780px;
	}
`;

const BestWay = () => (
	<StyledBestWay>
		<ContentSection
			title={'The Best Way to Build a Customized App or Website'}
			subtitle={`
			At <b>Pixelteh</b>, web development is a holistic process. Our websites don’t only look great, they also perform.<br />
			From the start, we consider your content strategy, information architecture, user-pathways and how to market your site to your audience.
		`}
			seoTitle={'h2'}
			seoSubTitle={'h4'}
		>
			<P>
				Creating and building a successful mobile app, website, or coding
				project starts with a solid programming strategy. Do to our expertise
				and experience, we can design, develop, and deploy anything from small
				apps to large-scale websites. When you depend on <b>PixelTeh</b>, you
				work with a company that knows programming and understands your business
				goals. Using a wide range of coding options and platforms, we develop
				and deliver a professional product that perfectly fits your company’s
				image, needs, and objectives. With a detailed and customized web/mobile
				development strategy, we help your company achieve your business goals
				quickly. This allows you to expand into new markets, leverage resources,
				cut costs, and improve operational efficiency. From creating iPhone and
				Android apps to writing code for AI, VR, and ML, we truly are your go-to
				full-stack experts. We build modern applications of all shapes and sizes
				using the latest technologies.
			</P>
			<div className="steps-wrapper">
				<Steps />
			</div>
		</ContentSection>
	</StyledBestWay>
);

export default BestWay;
