import React from 'react';
import { shape, string } from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import DescriptionBlock from '../components/TechnologiesFullStackPage/PageDescription';
import TypographyQuestion from '../components/TechnologiesFullStackPage/Typography/';
import CodingLanguages from '../components/TechnologiesFullStackPage/CodingLanguages/';
import FullstackAccordeon from '../components/TechnologiesFullStackPage/FullstackAccordeon/';
import Reasons from '../components/TechnologiesFullStackPage/Reasons/';
import BuildMobile from '../components/TechnologiesFullStackPage/BuildMobile/';
import BestWay from '../components/TechnologiesFullStackPage/BestWay/';
import SubmitQuote from '../components/SubmitQuote/index';
import ForManyIndustries from '../components/TechnologiesFullStackPage/ForManyIndustries/';
import ContactForm from '../components/ContactForm/';
import ProgrammingHub from '../components/ProgrammingHub/';
import SubpageTopCover from '../components/SubpageTopCover';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { fullStack } from '../seoConfig';

const TechnologiesFullStackPage = ({ location }) => {
	const img = useHeroPictureTechnologies();
	return (
		<PageLayout location={location} seoConfig={fullStack}>
			<SubpageTopCover
				backgroundImg={img}
				altText={'pixelteh team with computers'}
			>
				Full-Stack Development
			</SubpageTopCover>
			<DescriptionBlock />
			<TypographyQuestion />
			<CodingLanguages />
			<FullstackAccordeon />
			<Reasons />
			<BuildMobile />
			<BestWay />
			<SubmitQuote />
			<ForManyIndustries />
			<ContactForm label="Full-stack Form" />
			<ProgrammingHub />
		</PageLayout>
	);
};
TechnologiesFullStackPage.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
};
export default TechnologiesFullStackPage;
