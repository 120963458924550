import styled from 'styled-components';
import { fontSizes } from '../../globalStyles';
import commonHeadingStyles from './commonStyles/commonHeadingStyles';

const H4 = styled.h4`
	font-size: ${fontSizes.h4};
	${commonHeadingStyles};
`;

export default H4;
