import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import H3 from '../../../_common/H3';
import P from '../../../_common/P';
import AnimatedOnScroll from './../../../../components/_common/AnimatedOnScroll';
import { colors, fonts, media } from '../../../../globalStyles';

const StyledStepItem = styled.div`
	width: 100%;
	${P} {
		line-height: 30px;
	}
	.steps-line-cover {
		padding: 70px 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		&:before,
		&:after {
			display: block;
			position: absolute;
			left: 50%;
			top: 0;
			height: 50%;
			width: 1px;
			background: #e8e8e8;
			content: '';
		}

		&:after {
			top: auto;
			bottom: 0;
			${({ noLineAfter }) => css`
				display: ${noLineAfter ? 'none' : 'block'};
			`};
		}
		&:before {
			${({ noLineBefore }) => css`
				display: ${noLineBefore ? 'none' : 'block'};
			`};
		}
		${media.maxXs`
			padding: 0;
		`}
	}
	.step-item {
		width: 48%;
		max-width: 418px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		align-self: center;
		span {
			color: #f9f9f9;
			font-size: 200px;
			font-family: ${fonts.openSans};
			text-align: center;
			${media.maxXs`
				font-size: 80px;
			`}
		}
		${H3} {
			font-size: 18px;
			font-family: ${fonts.openSansSembiBold};
			${media.maxXs`
				font-size: 14px;
			`}
		}
		${P} {
			margin-top: 24px;
			font-family: ${fonts.openSansLightItalic};
			text-align: center;
		}
	}
	.center {
		align-self: center;
		background: ${colors.orange};
		position: relative;
		z-index: 5;
		width: 16px;
		height: 16px;
		box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
		border-radius: 50%;
	}
`;

const StepItem = ({
	noLineBefore,
	noLineAfter,
	revers,
	number,
	title,
	text,
}) => {
	return (
		<StyledStepItem noLineBefore={noLineBefore} noLineAfter={noLineAfter}>
			<div className={`steps-line-cover ${revers ? 'flex-row-reverse' : ''}  `}>
				<div className="step-item ">
					<AnimatedOnScroll
						animateIn={!revers ? 'fadeInRight' : 'fadeInLeft'}
						animateOut={!revers ? 'fadeOutLeft' : 'fadeOutRight'}
					>
						<span>{number}</span>
					</AnimatedOnScroll>
				</div>
				<div className="center" />

				<div className="step-item">
					<AnimatedOnScroll
						animateIn={revers ? 'fadeInRight' : 'fadeInLeft'}
						animateOut={!revers ? 'fadeOutLeft' : 'fadeOutRight'}
					>
						<H3>{title}</H3>
						<P className="hidden-xs">{text}</P>
					</AnimatedOnScroll>
				</div>
			</div>
		</StyledStepItem>
	);
};

StepItem.propTypes = {
	revers: PropTypes.bool,
	number: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	noLineBefore: PropTypes.bool,
	noLineAfter: PropTypes.bool,
};
StepItem.defaultProps = {
	noLineBefore: false,
	noLineAfter: false,
};
export default StepItem;
