import React from 'react';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import styled from 'styled-components';

const StyledDescriptionBlock = styled.div`
	${P} {
		text-align: left;
		margin-top: 30px;
		margin-bottom: 0;
	}
`;

const DescriptionBlock = () => (
	<StyledDescriptionBlock>
		<PageDescription
			title="Fuel Your Website & Mobile Apps with Full-Stack Development"
			subtitle="Cross-functional solutions for the front-end and back-end"
			bgColor="white"
			textPaddingBottom={0}
		>
			<P className="text-left">
				Save time, resources, and money by beginning your website or app project
				on the right foundation. <strong>PixelTeh</strong> is a full-stack
				development company that keeps the big picture in mind when managing
				your project. And yet . . . we also take care of the “behind-the-scene”
				details that the end-user will never see. We are experts at both
				front-end and back-end development. As a full-stack developer, we adhere
				to a holistic approach to development – from concept to design to coding
				to completion. We understand what the end-user needs as well as what
				your business demands. This means a website or app that looks good and
				functions the way it should. Our full-stack engineers are experienced in
				all of the latest technologies. The end result? Code that is scalable,
				clean, and flawless.
			</P>
		</PageDescription>
	</StyledDescriptionBlock>
);

export default DescriptionBlock;
