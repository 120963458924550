import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import Img from 'gatsby-image';
import H3 from '../_common/H3';
import P from '../_common/P';
import { colors, fonts, media } from '../../globalStyles';
import bgStripes from '../../assets/img/border-vertical.png';

export const StyledTile = styled.div`
	width: 30.2%;
	margin-bottom: 108px;
	position: relative;
	display: flex;
	padding-right: 24px;
	padding-left: 24px;
	&:before,
	&:after {
		position: absolute;
		display: none;
		content: '';
		width: 20px;
		height: 100%;
		top: 0;
		background: url(${bgStripes}) repeat;
	}
	&:before{
		left: -36px;
	}
	&:after{
		right: -36px;
	}
	${media.minLaptop`
		&:nth-child(3n+2) {
			// width: 38%;
			margin-left: 52px;
    	margin-right: 52px;
			&:before,
			&:after {
				display: block;
			}
		}
	`}
	${media.laptop`
		width: calc(50% - 10px);
		&:nth-child(2n+1) {
			&:after {
				display: block;
				right: -20px;
			}
		}
	`}
	${media.tablet`
		width:100%!important;
		&:nth-child(2n+1) {
			&:after{
				display: none;
			};
		};
	`}
	${media.maxXs`
		margin-bottom: 40px
	`}
	&:first-child {
		.center {
			display: none;
		}
	}
	${({isLinkTile}) => isLinkTile && css`
		&:hover{
			.gatsby-image-wrapper{
				transform: scale(1.1);
			}
		}
	`}
	.tile-inner{
		flex: 1 1 0%;
		text-align: center;
		width: 100%;
		text-decoration: none;
		color: inherit;
	}
	
	.image-wrapper{
		width: 100%;
    height: 180px;
    overflow: hidden;
    position: relative;
    ${media.laptop`
    	height: 220px;
    `}
	}
	
	.gatsby-image-wrapper{
		position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%!important;
    height: 100%!important;
    transition: all 0.3s linear;
    ${media.laptop`
    	img {
				object-position: top!important;
    	}
    `}
	}

	// img
	${H3} {
		margin-top: 40px;
		font-size: 22px;
		color: ${colors.blackTitle};
		font-family: ${fonts.robotoMedium};
	}
	${P} {
		margin-top: 40px;
		font-size: 15px;
		color: #929398;
		font-family: ${fonts.robotoLightItalic};
		line-height: 30px;
	}
	${media.maxXs`
		padding: 0;
	`}

	.center {
		display: none;
		margin: 0 auto 40px;
		background: #fd4326;
		z-index: 5;
		width: 16px;
		height: 16px;
		box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
		border-radius: 50%;
		${media.maxXs`
			display: block;
		`}
	}
`;

const WrapperElement = ({ tag, children, ...props }) => {
	return React.createElement(tag, props, children);
};

WrapperElement.defaultProps = {
	tag: 'div',
};
WrapperElement.propTypes = {
	tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	children: PropTypes.any,
};

const Tile = (props) => {
	const isLinkTile = props.tag !== 'div';
	const linkProps = isLinkTile && { tag: props.tag, to: `/blog/${props.slug}` };
	return (
		<StyledTile isLinkTile={isLinkTile}>
			<WrapperElement {...linkProps} className="tile-inner">
				<div className="center" />
				<div className="image-wrapper">
					{typeof props.img === 'object'
						? <Img fixed={props.img.childImageSharp.fixed} />
						: <img src={props.img} alt={props.alt} />
					}
				</div>
				<H3>{props.title}</H3>
				<P dangerouslySetInnerHTML={{ __html: props.text }} />
				{props.children}
			</WrapperElement>
		</StyledTile>
	);
};

Tile.propTypes = {
	img: PropTypes.any,
	children: PropTypes.any,
	id: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	slug: PropTypes.string,
	text: PropTypes.string,
	tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Tile;
