import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './../../../components/_common/Container';

import moreIcon from '../../../assets/img/more-icon-alt.png';
import H3 from '../../_common/H3';
import P from '../../_common/P';
import { colors, fonts, media } from '../../../globalStyles';
import { Link } from 'gatsby';

const StyledReasons = styled.div`
	background-color: #365277;
	padding-top: 54px;
	padding-bottom: 48px;
	${H3} {
		color: ${colors.white};
		font-family: ${fonts.robotoMedium};
	}
	a {
		color: ${colors.white};
		font-weight: bold;
	}
	.container {
		max-width: 1511px;
	}
	.reasons-text-cover {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		${P} {
			width: 50%;
			margin-top: 10px;
			${media.maxXs`
				width: 100%;
			`}
		}
	}
	${P} {
		margin-top: 20px;
		padding-left: 8px;
		padding-right: 8px;
		color: ${colors.white};
		line-height: 30px;
		img {
			margin-right: 12px;
		}
	}
`;

const ReasonItem = ({ children }) => (
	<P>
		<img src={moreIcon} alt="" />
		{children}
	</P>
);
ReasonItem.propTypes = {
	children: PropTypes.any,
};

const Reasons = () => (
	<StyledReasons>
		<Container>
			<H3>
				Reasons to Use <b>PixelTeh</b> to Grow Your Business
			</H3>
			<div className="reasons-text-cover">
				<ReasonItem>
					Custom front-end, back-end, and complete web services
				</ReasonItem>
				<ReasonItem>
					Complex deployment for hosting, dedicated, and Cloud servers
				</ReasonItem>
				<ReasonItem>
					Application of a pre-built content management system (CMS) or a custom
					one
				</ReasonItem>
				<ReasonItem>
					Full-scale web apps (fully accessible on mobile devices)
				</ReasonItem>
				<ReasonItem>
					Integration of Cloud services, such as Amazon AWS and Apple iCloud
				</ReasonItem>
				<ReasonItem>
					Incorporation of Google Maps, Facebook, LinkedIn, PayPal, etc.
				</ReasonItem>
				<ReasonItem>
					Complete development from idea to deployment to ongoing maintenance
				</ReasonItem>
			</div>
			<P className="hidden-xs">
				Whether your project involves a new mobile app, an eCommerce solution, a
				custom-branded website, or a corporate portal, you can trust{' '}
				<Link to="/team">PixelTeh Team</Link> to fulfill your goals and exceed
				your expectations. You have all our expertise at your services. Reach
				out to us for full-stack development of your project. We’ll work
				together to create a website that keeps your business ahead of your
				competitors.
			</P>
		</Container>
	</StyledReasons>
);

export default Reasons;
