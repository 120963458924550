import { css } from 'styled-components';
import { colors, fonts, fontSizes } from '../../../globalStyles';

export default css`
	width: 100%;
	padding: 15px 27px 16px;
	border: 1px solid #dadada;
	border-radius: 10px;
	background-color: #fcfcfc;
	&,
	&::placeholder {
		color: ${colors.blackTitle};
		font-style: italic;
		font-family: ${fonts.openSans};
		font-size: ${fontSizes.p};
		opacity: 1;
	}
`;
