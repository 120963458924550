import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CenterButton from './../../../components/_common/CenterButton';
import ContentSection from './../../../components/ContentSection/';

import data from './data';
import TilesList from '../../TilesList';
import Tile, { StyledTile } from '../../TilesList/Tile';
import { media } from '../../../globalStyles';

const StyledIndustries = styled.div`
	${StyledTile}{
		img {
			width: auto;
			height: 80px;
			${media.maxXs`
				height: 60px;
			`}
		}
	}
`;

const ForManyIndustries = () => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		if (window.innerWidth < 480) {
			setIsMobile(true);
		}
	}, []);
	const iconFieldName = (isMobile && 'mobileIcon') || 'icon';
	return (
		<ContentSection
			fluid
			title="Full-Stack Programming for Many Types of Industries"
			subtitle="Diversified and exceptional development services to meet the needs of every client."
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<StyledIndustries>
				<TilesList data={data}>
					{data.map((industry, index) => (
						<Tile
							key={index}
							img={industry[iconFieldName]}
							alt={industry.alt}
							title={industry.title}
							text={industry.text}
						/>
					))}
				</TilesList>
			</StyledIndustries>
			<div className="center-button-cover">
				<CenterButton
					linkTo="/contact#contact-section"
					gaLabel={'technologies-crossplatform Start A Project with us'}
				>
					Start A Project with us
				</CenterButton>
			</div>
		</ContentSection>
	);
};

export default ForManyIndustries;
