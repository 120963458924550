import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import SingleItem from './SingleItem';

import exp from '../../../assets/img/user-experience.png';
import responsive from '../../../assets/img/responsive.png';
import scalability from '../../../assets/img/scalability.png';
import reliability from '../../../assets/img/realiability.png';
import security from '../../../assets/img/security.png';
import { media } from '../../../globalStyles';

import CenterButton from './../../../components/_common/CenterButton';

import { mobileItemsContent } from './mobileItemsContent';

const itemsImages = [
	{
		icon: exp,
		alt: 'compass and protractor',
	},
	{
		icon: responsive,
		alt: 'computer and cellphone',
	},
	{
		icon: scalability,
		alt: 'ball with three arrows',
	},
	{
		icon: reliability,
		alt: 'cloud with checkmark',
	},
	{
		icon: security,
		alt: 'security badge',
	},
];

const StyledBuildMobile = styled.div`
	max-width: 1510px;
	margin: auto;
	${media.maxXxl`
		max-width: 1370px;
	`}
	.build-mobile-content-cover {
		padding-right: 20px;
		padding-left: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		${media.laptopS`
			justify-content: space-around;
			
	`}
	}
	.center-button-cover {
		margin-top: 106px;
		${media.maxSm`
			margin-top: 50px;
		`}
	}
`;

const BuildMobile = () => (
	<ContentSection
		title={'How to Build a Mobile or Web App'}
		subtitle="Websites & Mobile Apps That Look Great and Perform!"
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledBuildMobile>
			<div className="build-mobile-content-cover">
				{mobileItemsContent.map((item, index) => (
					<SingleItem
						key={item.title}
						image={itemsImages[index]}
						title={item.title}
					>
						{item.text}
					</SingleItem>
				))}
			</div>
			<div className="center-button-cover">
				<CenterButton
					linkTo="/contact#contact-section"
					gaLabel={'technologies-crossplatform Start A Project with us'}
				>
					Start A Project with us
				</CenterButton>
			</div>
		</StyledBuildMobile>
	</ContentSection>
);

export default BuildMobile;
