import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionItem } from 'react-sanfona';

import Container from './../../../components/_common/Container';

import python from '../../../assets/img/python-icon.png';
import java from '../../../assets/img/java-icon.png';
import js from '../../../assets/img/fs-icon2.png';
import objectiveC from '../../../assets/img/apple-icon.png';
import angular from '../../../assets/img/angular-icon.png';
import react from '../../../assets/img/fs-icon2.png';
import django from '../../../assets/img/django-icon.png';
import vue from '../../../assets/img/fs-icon7.png';
import laravel from '../../../assets/img/laravel-icon.png';
import loopBack from '../../../assets/img/fs-icon8.png';
import mySql from '../../../assets/img/mysql-icon.png';
import mongo from '../../../assets/img/fs-icon9.png';
import couch from '../../../assets/img/fs-icon10.png';
import maria from '../../../assets/img/fs-icon11.png';
import elastic from '../../../assets/img/fs-icon12-200x81.png';
import postgres from '../../../assets/img/fs-icon13.png';
import google from '../../../assets/img/fs-icon14.png';
import aws from '../../../assets/img/fs-icon15.png';
import heroku from '../../../assets/img/fs-icon16.png';
import digitalOcean from '../../../assets/img/fs-icon17.png';
import docker from '../../../assets/img/fs-icon18.png';
import node from '../../../assets/img/node-icon.png';
import arrow from '../../../assets/img/right-arrow.svg';

import TechIcons from '../../TechnologiesPage/TechIcons';

import P from '../../_common/P';
import { colors, fonts, media } from '../../../globalStyles';

const iconsLanguages = [
	{
		icon: python,
		alt: 'python logo',
	},
	{
		icon: node,
		alt: 'nodejs logo',
	},
	{
		icon: js,
		alt: 'js logo',
	},
	{
		icon: objectiveC,
		alt: 'objective c logo',
	},
	{
		icon: java,
		alt: 'java logo',
	},
];

// const iconsLanguagesXs = [python, php, node, js, objectiveC, java];
const iconsFrameworks = [
	{
		icon: angular,
		alt: 'angularjs logo',
	},
	{
		icon: react,
		alt: 'reactjs logo',
	},
	{
		icon: django,
		alt: 'django logo',
	},
	{
		icon: vue,
		alt: 'vuejs logo',
	},
	{
		icon: laravel,
		alt: 'laravel logo',
	},
	{
		icon: loopBack,
		alt: 'loopback io logo',
	},
];
const iconsDatabases = [
	{
		icon: mySql,
		alt: 'mysql icon',
	},
	{
		icon: mongo,
		alt: 'mongo db logo',
	},
	{
		icon: couch,
		alt: 'couch db logo',
	},
	{
		icon: maria,
		alt: 'maria db logo',
	},
	{
		icon: elastic,
		alt: 'elastic logo',
	},
	{
		icon: postgres,
		alt: 'postgres SQL logo',
	},
];

const iconsPlatforms = [
	{
		icon: google,
		alt: 'google cloud platform icon',
	},
	{
		icon: aws,
		alt: 'AWS logo',
	},
	{
		icon: heroku,
		alt: 'heroku logo',
	},
	{
		icon: digitalOcean,
		alt: 'digital ocean icon',
	},
	{
		icon: docker,
		alt: 'docker icon',
	},
];

const StyledFullstackAccordeon = styled.div`
	max-width: 1510px;
	margin: 0 auto 40px;
	${media.maxSm`
		background: #fbfbfb;
		margin: 0;
		padding-bottom: 40px;
	`}
	.accordion-text-cover {
		margin-top: 106px;
		${media.maxXs`
			margin-top: 40px
		`}
	}
	.react-sanfona-item {
		border-bottom: 1px dotted ${colors.greyItalicTitle};
		padding: 30px 0 60px;
		${media.maxXs`
			padding: 15px 0;
			border: none;
		`}
		&:last-child {
			border: none;
		}
		&:not(.react-sanfona-item-expanded) {
			.accordion-title {
				${media.maxXs`
					padding-left: 15px;
					border: none;
				`}
			}
		}
	}
	.accordion-title {
		width: 100%;
		margin-bottom: 0;
		color: ${colors.orange};
		font-family: ${fonts.robotoMedium};
		font-size: 32px;
		align-items: center;
		position: relative;
		display: inline-block;
		${media.maxXs`
			color: ${colors.blackTitle};
			font-size: 24px;
			&:after{
				margin-left: 16px
				height: 27px;
    		width: 27px;
				display: inline-block;
    		vertical-align: text-bottom;
				content: "";
				line-height: 0;
				background-image: url(${arrow});
				background-size: contain;
				background-position: center;
			}
		`}
	}
	${P} {
		line-height: 30px;
	}
	.react-sanfona-item-expanded {
		.accordion-title {
			${media.maxXs`
				text-align: center;
				color: ${colors.orange};
				&:after{
					display: none;
				}
		`}
		}
	}
	.icons-block-cover {
		margin-top: 80px;
		${media.maxXs`
			margin-top: 30px
		`}
	}
`;

const FullstackAccordeon = () => (
	<StyledFullstackAccordeon>
		<Container fluid>
			<Accordion>
				<AccordionItem
					title={<h3 className="accordion-title">Coding Languages</h3>}
				>
					<div className="icons-block-cover">
						<TechIcons icons={iconsLanguages} hideSm={false} visibleSm />
					</div>
					<div className="accordion-text-cover">
						<P>
							When you go to a mechanic, you expect that person to know
							everything about cars. The same is true for full-stack developers.
							Except we’re not talking about cars; we’re talking about code! Our
							experts are familiar with everything involving coding language.
							This ensures that they can work with back-end and front-end
							developers – and actually know what needs to be done. From mobile
							programming and web development to AI and ML apps, they are well
							versed in everything related to code.
						</P>
					</div>
				</AccordionItem>
				<AccordionItem title={<h3 className="accordion-title">Frameworks</h3>}>
					<div className="icons-block-cover">
						<TechIcons icons={iconsFrameworks} hideSm={false} visibleSm />
					</div>
					<div className="accordion-text-cover">
						<P>
							A building cannot stand if it doesn’t have a frame. The frame is
							the primary structure that holds it all together. A technological
							framework is a standardized set of concepts, practices, and
							criteria that help us approach a project and resolve problems. It
							is a package made up of a structure of files and folders of
							standardized code (HTML, CSS, JS documents, etc.). At{' '}
							<b>PixelTeh</b>, we use the latest and greatest frameworks and
							organizational structure to streamline our projects. By doing so,
							we fulfill our projects faster and more affordably for our
							clients.
						</P>
						<br />
						<br />
						<br />
						<P className="text-content">
							Much like the frame of a physical structure, our framework
							consists of the building blocks that firm a foundation for your
							project. It provides a common structure we can always reference so
							we don’t have to start over from scratch if we encounter any
							issue. Some of the frameworks we use are Django, Flash, React, and
							Angular.
						</P>
					</div>
				</AccordionItem>
				<AccordionItem title={<h3 className="accordion-title">Databases</h3>}>
					<div className="icons-block-cover">
						<TechIcons icons={iconsDatabases} hideSm={false} visibleSm />
					</div>
					<div className="accordion-text-cover">
						<P>
							Data, data, and more data! It takes a lot of data and code to
							develop any project. For instance, the average iPhone app has
							about 50,000 lines of code. Online games have more than 50 million
							lines of code! How do you store all of that data? The team at
							<b>PixelTeh</b> uses several solutions to store code, folders, and
							files. The database we use for your project allows interface to
							fetch, store, and display code that is a necessity to ensure a
							smooth-running application.
						</P>
					</div>
				</AccordionItem>
				<AccordionItem title={<h3 className="accordion-title">Platforms</h3>}>
					<div className="icons-block-cover">
						<TechIcons icons={iconsPlatforms} hideSm={false} visibleSm />
					</div>
					<div className="accordion-text-cover">
						<P>
							A platform is the complete package. It encompasses all the
							necessary components, application interfaces, and libraries
							required for our team to create, compile, debug, and execute
							applications. We use a range of platforms depending on the
							specific needs for a project. From AWS to Heroku to Google App
							Engine, we choose the right one that ensures the best results
							based on the goals our clients have.
						</P>
					</div>
				</AccordionItem>
			</Accordion>
		</Container>
	</StyledFullstackAccordeon>
);

export default FullstackAccordeon;
