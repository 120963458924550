import styled from 'styled-components';
import { fontSizes } from '../../globalStyles';
import commonHeadingStyles from './commonStyles/commonHeadingStyles';

const H5 = styled.h5`
	font-size: ${fontSizes.h5};
	${commonHeadingStyles};
`;

export default H5;
