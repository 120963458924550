import React from 'react';
import styled from 'styled-components';

// Images
import locationIcon from '../../assets/img/location-icon.png';

// Styles
import { media, fonts } from '../../globalStyles';

//----------------------------------------------------------------------------------------------------------------------
//
// const laptopTabletMedia = `
// 	&&& {
// 		width: 300px;
// 		height: 300px;
// 		figure {
// 			max-width: 88px;
// 			max-height: 126px;
// 			margin: 20px auto 5px;
// 		}
// 	}
// `;

const Wrapper = styled.div`
	width: 400px;
	height: 400px;
	background: rgba(54, 82, 119, 0.5);
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 6%;
	transform: translateX(-50%);
	z-index: 1;
	display: block;
	figure {
		width: 100%;
    height: 100%;
    max-width: 116px;
    max-height: 172px;
    margin: 35px auto 25px;
    ${media.laptopS`
			text-align: center;
			height:auto;
			with:auto;
		`} 
		${media.tablet`
			margin: 15px auto 10px;
		`} 
	}
	address {
		color: white;
    font-family: ${fonts.robotoRegular};
    font-size: 15px;
    font-style: italic;
    line-height: 22px;
    max-width: 210px;
    margin: 0 auto;
    text-align: center;
	}
	span {
		color: white;
    font-family: ${fonts.loveloBlack};
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    display: block;
    text-align: center;
    padding-bottom: 30px;
    padding-top: 20px;
	}
	img {
		width: 100%;
		height: 100%;
		${media.laptopS`
			width: auto;
			height: 100px;
		`}
		${media.tablet`
			height: 60px;
		`} 
	}
	${media.maxMd`
		figure {
    	max-width: 63px;
    	max-height: 95px;
    }
	`}
	${media.laptopS`
			width: 300px;
			height: 300px;
	`} 
	${media.tablet`
		height:230px;
		width: 230px;
	`} 
	
`;

const PanoramaInfo = () => (
	<Wrapper>
		<figure>
			<img alt={'location'} src={locationIcon} />
		</figure>
		<address>
			11 Mayakovskogo Avenue, Zaporizhzhya, Ukraine,
			<br />
			69118
		</address>
		<span>panorama view</span>
	</Wrapper>
);

export default PanoramaInfo;
