import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../globalStyles';
import Container from '../_common/Container';

const StyledTilesList = styled(Container)`
	//.container {
	max-width: 1554px;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	${media.minLaptop`
		justify-content: flex-start;
	`}
	${media.laptop`
		max-width: 1100px
	`}
	//}
`;

const TilesList = props => {
	return (
		<StyledTilesList>
			{props.children}
		</StyledTilesList>
	);
};

TilesList.propTypes = {
	children: PropTypes.any,
};
export default TilesList;
